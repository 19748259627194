<template>
  <section>
    <div class="mb-4">

       
     <b-nav tabs class="hidden-not-mobile">
      <b-nav>
       <b-nav-item-dropdown id="my-nav-dropdown" toggle-class="nav-link-custom">
         <template #button-content>
           {{ GetSectionTitle(section) }}
         </template>
         <b-dropdown-item @click="SetSection(0)" :active="section == 0">{{ GetSectionTitle(0) }}</b-dropdown-item>
         <b-dropdown-item @click="SetSection(1)" :active="section == 1">{{ GetSectionTitle(1) }}</b-dropdown-item>
       </b-nav-item-dropdown>
     </b-nav>
    </b-nav>

    <b-nav tabs class="hidden-mobile">       
      <b-nav-item @click="SetSection(0)" :active="section == 0">{{ GetSectionTitle(0) }}</b-nav-item>
      <b-nav-item @click="SetSection(1)" :active="section == 1">{{ GetSectionTitle(1) }}</b-nav-item>
    </b-nav>

    
     <b-nav tabs class="hidden-not-mobile">
       <b-nav>
        <b-nav-item-dropdown id="my-nav-dropdown" toggle-class="nav-link-custom">
          <template #button-content>
            {{  GetTitle(selected)  }}
          </template>
          <b-dropdown-item @click="SetSelected(0)" :active="selected == 0" v-if="section == 0">{{ GetTitle(0) }}</b-dropdown-item>
          <b-dropdown-item @click="SetSelected(1)" :active="selected == 1" v-if="section == 0">{{ GetTitle(1) }}</b-dropdown-item>
          <b-dropdown-item @click="SetSelected(2)" :active="selected == 2" v-if="section == 0">{{ GetTitle(2) }}</b-dropdown-item>
          <b-dropdown-item @click="SetSelected(6)" :active="selected == 6" v-if="section == 0">{{ GetTitle(6) }}</b-dropdown-item>
          <b-dropdown-item @click="SetSelected(3)" :active="selected == 3" v-if="section == 1">{{ GetTitle(3) }}</b-dropdown-item>
          <b-dropdown-item @click="SetSelected(4)" :active="selected == 4" v-if="section == 1">{{ GetTitle(4) }}</b-dropdown-item>
          <b-dropdown-item @click="SetSelected(5)" :active="selected == 5" v-if="section == 1">{{ GetTitle(5) }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav>
     </b-nav>

      <b-nav tabs class="hidden-mobile">       
        <b-nav-item @click="SetSelected(0)" :active="selected == 0" v-if="section == 0">{{ GetTitle(0) }}</b-nav-item>
        <b-nav-item @click="SetSelected(1)" :active="selected == 1" v-if="section == 0">{{ GetTitle(1) }}</b-nav-item>
        <b-nav-item @click="SetSelected(2)" :active="selected == 2" v-if="section == 0">{{ GetTitle(2) }}</b-nav-item>
        <b-nav-item @click="SetSelected(6)" :active="selected == 6" v-if="section == 0">{{ GetTitle(6) }}</b-nav-item>
        <b-nav-item @click="SetSelected(3)" :active="selected == 3" v-if="section == 1">{{ GetTitle(3) }}</b-nav-item>
        <b-nav-item @click="SetSelected(4)" :active="selected == 4" v-if="section == 1">{{ GetTitle(4) }}</b-nav-item>
        <b-nav-item @click="SetSelected(5)" :active="selected == 5" v-if="section == 1">{{ GetTitle(5) }}</b-nav-item>
      </b-nav>

      <b-row class="mt-2">
        <b-col cols="2">
           <PickDate
           v-model="from_date"
           :use-label="$t('FROM')"
           @change="SetSelected(selected)"></PickDate>
        </b-col>
        <b-col cols="2">
          <PickDate
          v-model="to_date"
          :use-label="$t('TO')"
          @change="SetSelected(selected)"></PickDate>
        </b-col>
      </b-row>

      <b-table
      striped class="mt-2"
      hover
      :key="'statisticsInformation_' + redrawIndex"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
    >
    <template v-slot:cell(mobile1)="data">
      <span v-if="selected == 0 || selected == 1">{{  data.item.name }}</span>

      <span v-if="selected == 2 || selected == 5">{{  data.item.name }}<br />{{ FormatDateTime(data.item.date) }}</span>
      
      <span v-if="selected == 3">{{  data.item.name }}<br />{{  data.item.email }}</span>
      
      <span v-if="selected == 4">{{  data.item.name }}<br />{{  data.item.email }}</span>

      <span v-if="selected == 6">{{  data.item.type }}</span>
    </template>

    <template v-slot:cell(mobile2)="data">
      <span v-if="selected == 0 || selected == 1">{{  data.item.count }}</span>

      <span v-if="selected == 2 || selected == 5">{{  data.item.count }}</span>

      <span v-if="selected == 3">{{  data.item.adminAccounts }}</span>
      
      <span v-if="selected == 4">{{ GetSefosMessages(data.item) }}</span>
      
      <span v-if="selected == 6">{{  data.item.incoming }}</span>
    </template>

    

    <template v-slot:cell(mobile3)="data">
      <span v-if="selected == 3">{{  data.item.accounts }}</span>
      <span v-if="selected == 4">{{ GetSdkMessages(data.item) }}</span>
      <span v-if="selected == 6">{{  data.item.outgoing }}</span>
    </template>

    

    <template v-slot:cell(mobile4)="data">
      <span v-if="selected == 4">{{ GetFaxMessages(data.item) }}</span>
      <span v-if="selected == 6">{{ data.item.incoming + data.item.outgoing }}</span>
      
    </template>
    

    <template v-slot:cell(date)="data">
      {{ FormatDateTime(data.item.date) }}
    </template>

    <template v-slot:cell(total)="data">
      {{ data.item.incoming + data.item.outgoing }}
    </template>

    <template v-slot:cell(sefos)="data">
      {{ GetSefosMessages(data.item) }}
    </template>

    <template v-slot:cell(sdk)="data">
      {{ GetSdkMessages(data.item) }}
    </template>

    <template v-slot:cell(fax)="data">
      {{ GetFaxMessages(data.item) }}
    </template>

    </b-table>

    <b-pagination
    v-model="currentPage"
    :total-rows="totalRows"
    :per-page="perPage"
    aria-controls="statistics-paging"
  ></b-pagination>

    <b-button @click="csvExport">{{ $t('EXPORT') }}</b-button>
  
    </div>
  </section>
</template>
<script>
import { DateTime } from "luxon";
import PickDate from "@/components/Input/PickDate";
export default {
  props: ["organisation"],
  components: {
    PickDate
  },
  data() {
    return {
      currentPage: 1,
      totalRows: 0,
      perPage: 45,
      loaded: false,
      selected: 0,
      redrawIndex: 0,
      section: 0,
      from_date: new DateTime.now().plus({ months: -1 }).toUTC().toISO(),
      to_date: new DateTime.now().toUTC().toISO(),
      items: []
    };
  },
  methods: {   
    GetSectionTitle(id)
    { 
      switch(id)
      {
        case 0: return this.$t('ORGANISATION.TITLE');
        case 1: return this.$t('FUNCTIONBOX.TITLE');
      }
    },
    GetTitle(id)
    {
      switch(id)
      {
        case 0: return this.$t('STATISTICS.AUTHENTICATIONS');
        case 1: return this.$t('STATISTICS.CREATED_LINKS_AUTH');
        case 2: return this.$t('STATISTICS.ACCOUNTS_HISTORY');
        case 3: return this.$t('STATISTICS.FUNCTIONBOXES_ACTIVE');
        case 4: return this.$t('STATISTICS.FUNCTIONBOXES_MESSAGES');
        case 5: return this.$t('STATISTICS.FUNCTIONBOXES');
        case 6: return this.$t('STATISTICS.ORGANISATION_MESSAGES');
      }
    },
    csvExport()
    {
      let csvContent = "";
      for(let ix = 0; ix < this.fields.length; ix++)
      {
        if(ix != 0){ csvContent += ";"; }
        csvContent += this.fields[ix].label;        
      }
      csvContent += "\n";

      switch(this.selected)
      {
        case 0: 
        case 1:
          for (let i = 0; i < this.items.length; i++) {
            csvContent += this.items[i].name + ";";
            csvContent += this.items[i].count + "\n";
          }     
          break;
        case 2:
        case 5:
          for (let i = 0; i < this.items.length; i++) {
            csvContent += this.items[i].name + ";";
            csvContent += this.FormatDateTime(this.items[i].date) + ";";
            csvContent += this.items[i].count + "\n";
          }     
          break;
        case 3:
          for (let i = 0; i < this.items.length; i++) {
            csvContent += this.items[i].name + ";";
            csvContent += this.items[i].email + ";";
            csvContent += this.items[i].adminAccounts + ";";
            csvContent += this.items[i].accounts + "\n";
          }     
          break;
        case 4:
          for (let i = 0; i < this.items.length; i++) {
            csvContent += this.items[i].name + ";";
            csvContent += this.GetSefosMessages(this.items[i]) + ";";
            csvContent += this.GetSdkMessages(this.items[i]) + ";";
            csvContent += this.GetFaxMessages(this.items[i]) + "\n";
          }     
          break;
        case 6:
          for (let i = 0; i < this.items.length; i++) {
            csvContent += this.items[i].type + ";";
            csvContent += this.items[i].incoming + ";";
            csvContent += this.items[i].outgoing + ";";
            csvContent += this.items[i].incoming + this.items[i].outgoing + "\n";
          }     
          break;
      }
      var blob = new Blob(["\uFEFF" + csvContent], {
          type: 'text/csv; charset=utf-18'
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob, {
        type: "text/plain"
      });
      var filename = this.GetSectionTitle(this.section) + "_";
      filename += this.GetTitle(this.selected) + "_";
      filename += this.from_date.toLocaleDateString() + "_";
      filename += this.to_date.toLocaleDateString();
      filename += ".csv";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    GetSefosMessages(item)
    {
      if(item.data != undefined)
      {
        let found = item.data.filter((c) => c.type == "message");
        if(found.length != 0)
        {
          return found[0].incoming + found[0].outgoing;
        }
      }
      return 0;
    },
    GetSdkMessages(item)
    {
      if(item.data != undefined)
      {
        let found = item.data.filter((c) => c.type == "sdk-message");
        if(found.length != 0)
        {
          return found[0].incoming + found[0].outgoing;
        }
      }
      return 0;
    },
    GetFaxMessages(item)
    {
      if(item.data != undefined)
      {
        let found = item.data.filter((c) => c.type == "fax-message");
        if(found.length != 0)
        {
          return found[0].incoming + found[0].outgoing;
        }
      }
      return 0;
    },
    init(){
      this.SetSelected(0);
    },
    getLastMonth() {
      var fromDate = new Date();
      fromDate.setMonth(fromDate.getMonth() - 1);
      return fromDate;
    },
    async SetSection(section)
    {
      this.section = section;
      if(section == 0)
      {
        this.SetSelected(0);
      }else{
        this.SetSelected(3);
      }
    },
    async SetSelected(selection)
    {
      this.selected = selection;
      switch(selection)
      {
        case 0: 
          await this.FetchStatistics(this.user.hostname + "/statistics/authentications");
          break;
        case 1: 
          await this.FetchStatistics(this.user.hostname + "/statistics/external-identifications");
          break;
        case 2: 
          await this.FetchStatistics(this.user.hostname + "/statistics/accounts/history");
          break;          
        case 3: 
          await this.FetchStatistics(this.user.hostname + "/statistics/functionboxes/active");
          break;          
        case 4: 
          await this.FetchStatistics(this.user.hostname + "/statistics/functionboxes/messages");
          break;          
        case 5: 
          await this.FetchStatistics(this.user.hostname + "/statistics/functionboxes/history");
          break;          
        case 6: 
          await this.FetchStatistics(this.user.hostname + "/statistics/organisation/messages");
          break;                                                                      
      }
    },
    async FetchStatistics(url)
    {
      try {
        let StatisticsRequest =  {
          OrganisationUuid: this.organisation.organisation_uuid,
          From: this.from_date,
          To: this.to_date,
        };
        let result = await this.$http.post(
          url,
          StatisticsRequest
        );        
        this.items = result.data;
        this.totalRows = this.items.length;
        this.redrawIndex++;
      } catch (error) {
        return null;
      }
    }
  },
  computed: {
    fields() {
      switch(this.selected)
      {
        case 0: 
        case 1:
          return [          
            {
              key: "mobile1",
              label: "",
              class: "text-break clickable hidden-not-mobile",
              thClass: "hidden-not-mobile",
              sortable: false,
            }, 
            {
              key: "mobile2",
              label: "",
              class: "text-break clickable hidden-not-mobile",
              thClass: "hidden-not-mobile",
              sortable: false,
            }, 
            {
              key: "name",
              label: this.$t("NAME"),
              class: "text-break clickable hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "count",
              label: this.$t("AMOUNT"),
              class: "text-break w-date clickable hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            }
          ]; 
        case 2:
        case 5:
          return [           
            {
              key: "mobile1",
              label: this.$t("NAME"),
              class: "text-break7 hidden-not-mobile",
              thClass: "hidden-not-mobile",
              sortable: false,
            },      
            {
              key: "mobile2",
              label: this.$t("CHANGES"),
              class: "text-break hidden-not-mobile",
              thClass: "hidden-not-mobile",
              sortable: false,
            },   
            {
              key: "name",
              label: this.$t("NAME"),
              class: "text-break hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "date",
              label: this.$t("CREATED"),
              class: "text-break w-date hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "count",
              label: this.$t("CHANGES"),
              class: "text-break w-date hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            }
          ]; 
        case 3:
          return [              
            {
              key: "mobile1",
              label: "",
              class: "text-break hidden-not-mobile",
              thClass: "hidden-not-mobile",
              sortable: false,
            },  
            {
              key: "mobile2",
              label: this.$t("ADMIN"),
              class: "text-break w-icon hidden-not-mobile",
              thClass: "hidden-not-mobile",
              sortable: false,
            },
            {
              key: "mobile3",
              label: this.$t("ACCOUNTS"),
              class: "text-break w-icon hidden-not-mobile",
              thClass: "hidden-not-mobile",
              sortable: false,
            },
            {
              key: "name",
              label: this.$t("NAME"),
              class: "text-break hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "email",
              label: this.$t("EMAIL"),
              class: "text-break hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "adminAccounts",
              label: this.$t("ADMIN"),
              class: "text-break w-date hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "accounts",
              label: this.$t("ACCOUNTS"),
              class: "text-break w-date hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            }
          ]; 
        case 4:
          return [        
            {
              key: "mobile1",
              label: this.$t("NAME"),
              class: "text-break hidden-not-mobile",
              thClass: "hidden--not-mobile",
              sortable: false,
            },        
            {
              key: "mobile2",
              label: this.$t("MESSAGES.TYPE.message"),
              class: "text-break w-icon hidden-not-mobile",
              thClass: "hidden--not-mobile",
              sortable: false,
            },        
            {
              key: "mobile3",
              label: this.$t("MESSAGES.TYPE.sdk-message"),
              class: "text-break w-icon hidden-not-mobile",
              thClass: "hidden--not-mobile",
              sortable: false,
            },        
            {
              key: "mobile4",
              label: this.$t("MESSAGES.TYPE.fax-message"),
              class: "text-break w-icon hidden-not-mobile",
              thClass: "hidden--not-mobile",
              sortable: false,
            },     
            {
              key: "name",
              label: this.$t("NAME"),
              class: "text-break hidden-mobile",
              thClass: "hidden--mobile",
              sortable: false,
            },
            {
              key: "sefos",
              label: this.$t("MESSAGES.TYPE.message"),
              class: "text-break w-date hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "sdk",
              label: this.$t("MESSAGES.TYPE.sdk-message"),
              class: "text-break w-date hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "fax",
              label: this.$t("MESSAGES.TYPE.fax-message"),
              class: "text-break w-date  hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            }
          ]; 
        case 6:
          return [      
            {
              key: "mobile1",
              label: this.$t("NAME"),
              class: "text-break hidden-not-mobile",
              thClass: "hidden--not-mobile",
              sortable: false,
            },        
            {
              key: "mobile2",
              label: this.$t("STATISTICS.INCOMING"),
              class: "text-break w-icon hidden-not-mobile",
              thClass: "hidden--not-mobile",
              sortable: false,
            },        
            {
              key: "mobile3",
              label: this.$t("STATISTICS.OUTGOING"),
              class: "text-break w-icon hidden-not-mobile",
              thClass: "hidden--not-mobile",
              sortable: false,
            },        
            {
              key: "mobile4",
              label: this.$t("STATISTICS.TOTAL"),
              class: "text-break w-icon hidden-not-mobile",
              thClass: "hidden--not-mobile",
              sortable: false,
            },     
            {
              key: "type",
              label: this.$t("NAME"),
              class: "text-break clickable hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "incoming",
              label: this.$t("STATISTICS.INCOMING"),
              class: "text-break w-date clickable hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "outgoing",
              label: this.$t("STATISTICS.OUTGOING"),
              class: "text-break w-date clickable hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            },
            {
              key: "total",
              label: this.$t("STATISTICS.TOTAL"),
              class: "text-break w-date clickable hidden-mobile",
              thClass: "hidden-mobile",
              sortable: false,
            }
          ]; 
      }
      return [];
    }
  },
  mounted: function() {
    this.init();
  }
};
</script>
<style></style>
