var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      { staticClass: "mb-4" },
      [
        _c(
          "b-nav",
          { staticClass: "hidden-not-mobile", attrs: { tabs: "" } },
          [
            _c(
              "b-nav",
              [
                _c(
                  "b-nav-item-dropdown",
                  {
                    attrs: {
                      id: "my-nav-dropdown",
                      "toggle-class": "nav-link-custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n         " +
                                _vm._s(_vm.GetSectionTitle(_vm.section)) +
                                "\n       "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        attrs: { active: _vm.section == 0 },
                        on: {
                          click: function ($event) {
                            return _vm.SetSection(0)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.GetSectionTitle(0)))]
                    ),
                    _c(
                      "b-dropdown-item",
                      {
                        attrs: { active: _vm.section == 1 },
                        on: {
                          click: function ($event) {
                            return _vm.SetSection(1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.GetSectionTitle(1)))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-nav",
          { staticClass: "hidden-mobile", attrs: { tabs: "" } },
          [
            _c(
              "b-nav-item",
              {
                attrs: { active: _vm.section == 0 },
                on: {
                  click: function ($event) {
                    return _vm.SetSection(0)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.GetSectionTitle(0)))]
            ),
            _c(
              "b-nav-item",
              {
                attrs: { active: _vm.section == 1 },
                on: {
                  click: function ($event) {
                    return _vm.SetSection(1)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.GetSectionTitle(1)))]
            ),
          ],
          1
        ),
        _c(
          "b-nav",
          { staticClass: "hidden-not-mobile", attrs: { tabs: "" } },
          [
            _c(
              "b-nav",
              [
                _c(
                  "b-nav-item-dropdown",
                  {
                    attrs: {
                      id: "my-nav-dropdown",
                      "toggle-class": "nav-link-custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.GetTitle(_vm.selected)) +
                                "\n        "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm.section == 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { active: _vm.selected == 0 },
                            on: {
                              click: function ($event) {
                                return _vm.SetSelected(0)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.GetTitle(0)))]
                        )
                      : _vm._e(),
                    _vm.section == 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { active: _vm.selected == 1 },
                            on: {
                              click: function ($event) {
                                return _vm.SetSelected(1)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.GetTitle(1)))]
                        )
                      : _vm._e(),
                    _vm.section == 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { active: _vm.selected == 2 },
                            on: {
                              click: function ($event) {
                                return _vm.SetSelected(2)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.GetTitle(2)))]
                        )
                      : _vm._e(),
                    _vm.section == 0
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { active: _vm.selected == 6 },
                            on: {
                              click: function ($event) {
                                return _vm.SetSelected(6)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.GetTitle(6)))]
                        )
                      : _vm._e(),
                    _vm.section == 1
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { active: _vm.selected == 3 },
                            on: {
                              click: function ($event) {
                                return _vm.SetSelected(3)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.GetTitle(3)))]
                        )
                      : _vm._e(),
                    _vm.section == 1
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { active: _vm.selected == 4 },
                            on: {
                              click: function ($event) {
                                return _vm.SetSelected(4)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.GetTitle(4)))]
                        )
                      : _vm._e(),
                    _vm.section == 1
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { active: _vm.selected == 5 },
                            on: {
                              click: function ($event) {
                                return _vm.SetSelected(5)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.GetTitle(5)))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-nav",
          { staticClass: "hidden-mobile", attrs: { tabs: "" } },
          [
            _vm.section == 0
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { active: _vm.selected == 0 },
                    on: {
                      click: function ($event) {
                        return _vm.SetSelected(0)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.GetTitle(0)))]
                )
              : _vm._e(),
            _vm.section == 0
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { active: _vm.selected == 1 },
                    on: {
                      click: function ($event) {
                        return _vm.SetSelected(1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.GetTitle(1)))]
                )
              : _vm._e(),
            _vm.section == 0
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { active: _vm.selected == 2 },
                    on: {
                      click: function ($event) {
                        return _vm.SetSelected(2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.GetTitle(2)))]
                )
              : _vm._e(),
            _vm.section == 0
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { active: _vm.selected == 6 },
                    on: {
                      click: function ($event) {
                        return _vm.SetSelected(6)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.GetTitle(6)))]
                )
              : _vm._e(),
            _vm.section == 1
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { active: _vm.selected == 3 },
                    on: {
                      click: function ($event) {
                        return _vm.SetSelected(3)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.GetTitle(3)))]
                )
              : _vm._e(),
            _vm.section == 1
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { active: _vm.selected == 4 },
                    on: {
                      click: function ($event) {
                        return _vm.SetSelected(4)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.GetTitle(4)))]
                )
              : _vm._e(),
            _vm.section == 1
              ? _c(
                  "b-nav-item",
                  {
                    attrs: { active: _vm.selected == 5 },
                    on: {
                      click: function ($event) {
                        return _vm.SetSelected(5)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.GetTitle(5)))]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "b-row",
          { staticClass: "mt-2" },
          [
            _c(
              "b-col",
              { attrs: { cols: "2" } },
              [
                _c("PickDate", {
                  attrs: { "use-label": _vm.$t("FROM") },
                  on: {
                    change: function ($event) {
                      return _vm.SetSelected(_vm.selected)
                    },
                  },
                  model: {
                    value: _vm.from_date,
                    callback: function ($$v) {
                      _vm.from_date = $$v
                    },
                    expression: "from_date",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { cols: "2" } },
              [
                _c("PickDate", {
                  attrs: { "use-label": _vm.$t("TO") },
                  on: {
                    change: function ($event) {
                      return _vm.SetSelected(_vm.selected)
                    },
                  },
                  model: {
                    value: _vm.to_date,
                    callback: function ($$v) {
                      _vm.to_date = $$v
                    },
                    expression: "to_date",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("b-table", {
          key: "statisticsInformation_" + _vm.redrawIndex,
          staticClass: "mt-2",
          attrs: {
            striped: "",
            hover: "",
            fields: _vm.fields,
            items: _vm.items,
            "per-page": _vm.perPage,
            "current-page": _vm.currentPage,
          },
          scopedSlots: _vm._u([
            {
              key: "cell(mobile1)",
              fn: function (data) {
                return [
                  _vm.selected == 0 || _vm.selected == 1
                    ? _c("span", [_vm._v(_vm._s(data.item.name))])
                    : _vm._e(),
                  _vm.selected == 2 || _vm.selected == 5
                    ? _c("span", [
                        _vm._v(_vm._s(data.item.name)),
                        _c("br"),
                        _vm._v(_vm._s(_vm.FormatDateTime(data.item.date))),
                      ])
                    : _vm._e(),
                  _vm.selected == 3
                    ? _c("span", [
                        _vm._v(_vm._s(data.item.name)),
                        _c("br"),
                        _vm._v(_vm._s(data.item.email)),
                      ])
                    : _vm._e(),
                  _vm.selected == 4
                    ? _c("span", [
                        _vm._v(_vm._s(data.item.name)),
                        _c("br"),
                        _vm._v(_vm._s(data.item.email)),
                      ])
                    : _vm._e(),
                  _vm.selected == 6
                    ? _c("span", [_vm._v(_vm._s(data.item.type))])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "cell(mobile2)",
              fn: function (data) {
                return [
                  _vm.selected == 0 || _vm.selected == 1
                    ? _c("span", [_vm._v(_vm._s(data.item.count))])
                    : _vm._e(),
                  _vm.selected == 2 || _vm.selected == 5
                    ? _c("span", [_vm._v(_vm._s(data.item.count))])
                    : _vm._e(),
                  _vm.selected == 3
                    ? _c("span", [_vm._v(_vm._s(data.item.adminAccounts))])
                    : _vm._e(),
                  _vm.selected == 4
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.GetSefosMessages(data.item))),
                      ])
                    : _vm._e(),
                  _vm.selected == 6
                    ? _c("span", [_vm._v(_vm._s(data.item.incoming))])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "cell(mobile3)",
              fn: function (data) {
                return [
                  _vm.selected == 3
                    ? _c("span", [_vm._v(_vm._s(data.item.accounts))])
                    : _vm._e(),
                  _vm.selected == 4
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.GetSdkMessages(data.item))),
                      ])
                    : _vm._e(),
                  _vm.selected == 6
                    ? _c("span", [_vm._v(_vm._s(data.item.outgoing))])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "cell(mobile4)",
              fn: function (data) {
                return [
                  _vm.selected == 4
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.GetFaxMessages(data.item))),
                      ])
                    : _vm._e(),
                  _vm.selected == 6
                    ? _c("span", [
                        _vm._v(_vm._s(data.item.incoming + data.item.outgoing)),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "cell(date)",
              fn: function (data) {
                return [
                  _vm._v(
                    "\n    " +
                      _vm._s(_vm.FormatDateTime(data.item.date)) +
                      "\n  "
                  ),
                ]
              },
            },
            {
              key: "cell(total)",
              fn: function (data) {
                return [
                  _vm._v(
                    "\n    " +
                      _vm._s(data.item.incoming + data.item.outgoing) +
                      "\n  "
                  ),
                ]
              },
            },
            {
              key: "cell(sefos)",
              fn: function (data) {
                return [
                  _vm._v(
                    "\n    " + _vm._s(_vm.GetSefosMessages(data.item)) + "\n  "
                  ),
                ]
              },
            },
            {
              key: "cell(sdk)",
              fn: function (data) {
                return [
                  _vm._v(
                    "\n    " + _vm._s(_vm.GetSdkMessages(data.item)) + "\n  "
                  ),
                ]
              },
            },
            {
              key: "cell(fax)",
              fn: function (data) {
                return [
                  _vm._v(
                    "\n    " + _vm._s(_vm.GetFaxMessages(data.item)) + "\n  "
                  ),
                ]
              },
            },
          ]),
        }),
        _c("b-pagination", {
          attrs: {
            "total-rows": _vm.totalRows,
            "per-page": _vm.perPage,
            "aria-controls": "statistics-paging",
          },
          model: {
            value: _vm.currentPage,
            callback: function ($$v) {
              _vm.currentPage = $$v
            },
            expression: "currentPage",
          },
        }),
        _c("b-button", { on: { click: _vm.csvExport } }, [
          _vm._v(_vm._s(_vm.$t("EXPORT"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }