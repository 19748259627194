<template>
  <section class="mt-2">
    <b-overlay :show="loading" rounded="sm">

    <b-form-group
      label-cols-md="3"
      :label="$t('ORGANISATION.PARENT')"
      v-if="user.information.is_admin == 2"
    >
      <b-button size="sm" class="no-border" v-if="!parent_org" @click="chooseParent = true">{{ $t('CHOOSE') }}</b-button>
      <b-button size="sm" class="no-border" v-if="parent_org" @click="chooseParent = true">{{ $t('CHANGE') }}</b-button>
      <span v-if="parent_org">{{ parent_org.name }}</span>
    </b-form-group>


      <b-form-group
        label-cols-md="3"
        :label="$t('NAME')"
        v-if="user.information.is_admin == 2"
      >
        <b-form-input trim
          required
          type="text"
          v-model="name"
          name="name"
        ></b-form-input>
      </b-form-group>

    
      <b-form-group
        label-cols-md="3"
        :label="$t('LANGUAGE.TITLE')"
        v-if="user.information.is_admin == 2"
      >
        <b-form-select name="edit_user_select_language" v-model="lang">
          <b-form-select-option
            v-for="(value, key) in sefos_locales"
            :key="key"
            :value="key"
            >{{ value }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        v-if="user.information.is_admin == 2"
        :label="$t('ORGANISATION.UNIQUE_IDENTIFIER')"
      >
        <b-form-input trim
          required
          type="text"
          v-model="unique_identifier"
          name="email"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        class="mb-2"
        :label="$t('STATE')"
        v-if="
          user.information.is_admin == 2 &&
          user.information.organisation.id != information.id
        "
      >
        <b-form-select v-model="state" :options="stateOptions"></b-form-select>
      </b-form-group>

      <b-form-group label-cols-md="3" class="mb-2" :label="$t('TOKEN_TIMOUT')">
        <b-form-input trim
          class="w-150"
          type="number"
          v-model="token_timout"
          name="editTokenTimeOut"
        ></b-form-input>
        {{ $t("MINUTES") }}
      </b-form-group>

      <b-form-group label-cols-md="3" class="mb-2" :label="$t('ACTIVITY_TIMEOUT')">
        <b-form-input trim
          class="w-150"
          :state="activity_timeout >= 5 || activity_timeout == 0"
          type="number"
          v-model="activity_timeout"
          name="editActivityTimeOut"
        ></b-form-input>
        {{ $t("MINUTES") }}
        <b-form-text>{{ $t("ACTIVITY_TIMEOUT_MINIMUM") }}, {{ $t("0_DISABLED") }}</b-form-text>
      </b-form-group>
     
      

      <b-form-group
        label-cols-md="3"
        class="mb-2"
        :label="$t('EMAILSETTINGS.REMOVE_ACCOUNT_INFORMATION')"
      >
        <b-form-checkbox
          v-model="email_account_privacy"
          switch
          value="1"
          unchecked-value="0"
        >{{ $t('STATUS_ACTIVE') }}</b-form-checkbox>
        
      </b-form-group>

      <b-form-group label-cols-md="3" class="mb-2" :label="$t('OUT_OF_OFFICE')">
       
        <b-form-checkbox
          v-model="out_of_office_enabled"
          switch
          :value="true"
          :unchecked-value="false"
        >{{ $t('STATUS_ACTIVE') }}</b-form-checkbox>

      </b-form-group>


      <b-form-group
        label-cols-md="3"
        class="mb-2"
        :label="$t('EMAILSETTINGS.FORCE_SIGNATURE')"
      >
        <b-form-textarea
          v-model="email_signature"
          :placeholder="user.information.organisation.name"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label-cols-md="3" class="mb-2" :label="$t('COST_CENTER')">
        <b-form-input trim
          type="text"
          v-model="cost_center"
          name="editCostCenter"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        class="mb-2"
        :label="$t('BILLING_RECIPIENT')"
      >
        <b-form-input trim
          type="text"
          v-model="billing_receiver"
          :placeholder="$t('BILLING_RECIPIENT_DESCRIPTION')"
        ></b-form-input>
        <b-form-text>{{ $t("BILLING_RECIPIENT_DESCRIPTION") }}</b-form-text>
      </b-form-group>
      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" :disabled="disableSave" class="btn-fill" @click="editBtn">{{
        $t("SAVE")
      }}</b-button>

      <b-button
        variant="danger"
        v-if="user.information.organisation.id != information.id"
        class="btn-fill float-right"
        @click="showRemoveOrganisation = true"
        >{{ $t("REMOVE") }}</b-button
      >
    </b-overlay>

    <b-modal
      v-model="showRemoveOrganisation"
      :dialog-class="teams.theme"
      hide-header
      :ok-disabled="okRemoveDisabled"
      @cancel="cancelRemoveOrganisation"
      @ok="removeOrganisation"
      centered
      ok-variant="danger"
      :cancel-title="$t('CANCEL')"
      :ok-title="$t('REMOVE')"
    >
      <div v-html="$t('CONFIRM.DO_YOU_WANT_TO_REMOVE_ORGANISATION')"></div>
      
      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-form-group
        id="input-1"
        :label="$t('REMOVE_ORGANISATION_NAME')"
        label-for="input-2"
      >
      
        <b-alert show variant="info">{{ name }}</b-alert>
        <b-form-input trim
          v-model="remove_organisation_name"
          aria-describedby="label-help-block"
          placeholder=""
        ></b-form-input>
      </b-form-group>
    </b-modal>

    <b-modal
              id="choose-parent-organisation"
              v-model="chooseParent"
              hide-header
              ok-only
              @ok="closeChooseParent"
              centered
              :ok-title="$t('CANCEL')"
            >
              <b-form-input trim
                class="mt-2"
                v-model="search_organisation"
                :placeholder="$t('ORGANISATION.TITLE')"
              ></b-form-input>
              <b-button
                class="mt-2"
                @click="searchOrganisations"
                :disabled="search_organisation == ''"
                variant="primary"
                >{{ $t("FETCH_INFORMATION") }}</b-button
              >

              <b-table
                v-if="searchItems.length != 0"
                class="mt-2"
                striped
                hover
                :items="searchItems"
                :fields="searchFields"
              >

                <template v-slot:cell(name)="data">
                  <b-button
                    variant="dark"
                    size="sm"
                    @click="selectOrganisation(data.item)"
                    >{{ $t("CHOOSE") }}</b-button
                  > {{ data.item.name }} 
                </template>
              </b-table>

              <div v-if="searchDone && searchItems.length == 0">
                {{ $t("SDK.NOT_FOUND_SEARCH") }}
              </div>
            </b-modal>

  </section>
</template>
  <script>
export default {
  props: ["organisation"],
  data() {
    return {
      search_organisation: '',
      searchDone: false,
      searchItems: [],
      chooseParent: false,
      parent_org: null,
      remove_organisation_name: "",
      showRemoveOrganisation: false,
      nuiteq: {
        client_key: "",
        enabled: false,
        hostname: "",
        user_meetings_enabled: 0,
      },
      information: this.organisation,
      loading: false,
      show: false,
      name: "",
      validated: 0,
      users_can_remove: 1,
      unique_identifier: "",
      token_timout: 480,
      activity_timeout: 0,
      message_retention: 0,
      is_admin: 0,
      state: 0,
      message_response_default: 1,
      message_response_enabled: 1,
      email_account_privacy: 1,
      email_signature: "",
      email_edit_information_text: 1,
      email_information_text: "",
      email_notification_opened: 1,
      email_notification_responded: 1,
      meeting_secured_subject: 1,
      meeting_secured_body: 1,
      meeting_secured_enabled: 1,
      meeting_retention: 1,
      meeting_jitsi_enabled: 1,
      meeting_teams_enabled: 1,
      out_of_office_enabled: true,
      meeting_secured_send_ics: 1,
      meeting_secured_send_btn_text: "",
      cost_center: "",
      billing_receiver: "",
      accounts_can_delegate: 1,
      lang: "sv",
      searchFields: [
        {
          key: "name",
          label: "",
          class: "",
          thClass: "",
        }
      ],
    };
  },
  methods: {
    async getParentObj(organisation_uuid) {
      let self = this;
      let ListOrganisationsDTO = {
        page: 0,
        limit: 1,
        search_text: organisation_uuid
      };
      self.loading = true;
      await this.$http
        .post(this.user.hostname + "/administration/organisations/list", ListOrganisationsDTO)
        .then(function (result) {
          if(result.data.items)
          {
            self.parent_org = result.data.items[0];
            self.loading = false;
          }else{
            self.loading = false;          
          }
        })
        .catch(function () {
          self.loading = false;
        });
    },
    async searchOrganisations() {
      let self = this;
      let ListOrganisationsDTO = {
        page: 0,
        limit: 15,
        invited: 0,
        search_text: this.search_organisation
      };
      self.loading = true;
      await this.$http
        .post(this.user.hostname + "/administration/organisations/list", ListOrganisationsDTO)
        .then(function (result) {
          self.searchDone = true;
          self.searchItems = result.data.items;
          self.loading = false;          
        })
        .catch(function () {
          self.loading = false;
        });
    },
    selectOrganisation(item){
      this.parent_org = item;
      this.closeChooseParent();
    },
    closeChooseParent() {
      this.loading = false;
      this.searchDone = false;
      this.search_organisation = "";
      this.searchItems = [];
      this.chooseParent = false;
    },
    cancelRemoveOrganisation() {
      this.showRemoveOrganisation = false;
      this.remove_organisation_name = "";
    },
    editBtn: function () {
      let self = this;
      this.loading = true;
      let parent_uuid = null;
      if(this.parent_org)
      {
        parent_uuid = this.parent_org.organisation_uuid;
      }
      this.$http
        .post(this.user.hostname + "/organisation/settings/edit", {
          name: this.name,
          lang: this.lang,
          unique_identifier: this.unique_identifier,
          parent_uuid: parent_uuid,
          state: parseInt(this.state),
          organisation_id: this.information.id,
          users_can_remove: this.users_can_remove,
          token_timout: parseInt(this.token_timout),
          activity_timeout: parseInt(this.activity_timeout),          
          message_retention: parseInt(this.message_retention),
          message_response_default: this.message_response_default,
          message_response_enabled: parseInt(this.message_response_enabled),
          email_account_privacy: this.email_account_privacy,
          email_signature: this.email_signature,
          email_information_text: this.email_information_text,
          email_edit_information_text: parseInt(
            this.email_edit_information_text
          ),
          email_notification_privacy: this.email_notification_privacy,
          email_notification_opened: parseInt(this.email_notification_opened),
          email_notification_responded: parseInt(
            this.email_notification_responded
          ),
          meeting_secured_subject: this.meeting_secured_subject,
          meeting_secured_body: this.meeting_secured_body,
          meeting_secured_enabled: parseInt(this.meeting_secured_enabled),
          meeting_retention: this.meeting_retention,
          meeting_jitsi_enabled: parseInt(this.meeting_jitsi_enabled),
          meeting_teams_enabled: parseInt(this.meeting_teams_enabled),
          meeting_secured_send_ics: parseInt(this.meeting_secured_send_ics),
          out_of_office_enabled: this.out_of_office_enabled,
          meeting_secured_send_btn_text: this.meeting_secured_send_btn_text,
          accounts_can_delegate: parseInt(this.accounts_can_delegate),
          cost_center: this.cost_center,
          billing_receiver: this.billing_receiver,
        })
        .then(function () {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
          self.show = false;
        })
        .catch(function () {
        });
    },
    removeOrganisation: function () {
      let self = this;
      self.loading = true;
      self.$http
        .post(
          self.user.hostname + "/administration/organisation/remove",
          {
            organisation_id: self.information.id,
          }
        )
        .then(function () {
          self.$noty.info(self.$t("REMOVED"));
          self.$router
            .push({ name: "Administration.Organisations" })
            .catch(() => {});
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  computed: {
    disableSave(){
      return this.activity_timeout < 5 && this.activity_timeout > 0;
    },
    user() {
      return this.$store.state.user;
    },
    okRemoveDisabled() {
      return this.information.name != this.remove_organisation_name;
    },
  },
  mounted: function () {
    this.lang = this.information.lang;
    this.is_admin = this.information.is_admin;
    this.state = this.information.state;
    this.name = this.information.name;
    this.validated = this.information.validated;
    this.unique_identifier = this.information.unique_identifier;
    this.users_can_remove = this.information.users_can_remove;
    this.token_timout = this.information.token_timout;
    this.activity_timeout = this.information.activity_timeout;
    this.message_retention = this.information.message_retention;
    this.loa1_text = this.information.loa1_text;
    this.loa2_text = this.information.loa2_text;
    this.loa3_text = this.information.loa3_text;
    this.loa1_enabled = this.information.loa1_enabled;
    this.loa2_enabled = this.information.loa2_enabled;
    this.loa3_enabled = this.information.loa3_enabled;
    this.message_response_default = this.information.message_response_default;
    this.message_response_enabled = this.information.message_response_enabled;
    this.email_account_privacy = this.information.email_account_privacy;
    this.email_signature = this.information.email_signature;
    this.email_edit_information_text =
      this.information.email_edit_information_text;
    this.email_information_text = this.information.email_information_text;
    this.email_notification_opened = this.information.email_notification_opened;
    this.email_notification_responded =
      this.information.email_notification_responded;
    this.meeting_secured_enabled = this.information.meeting_secured_enabled;
    this.meeting_secured_subject = this.information.meeting_secured_subject;
    this.meeting_secured_body = this.information.meeting_secured_body;
    this.meeting_retention = this.information.meeting_retention;
    this.email_notification_responded =
      this.information.email_notification_responded;
    this.meeting_jitsi_enabled = this.information.meeting_jitsi_enabled;
    this.meeting_teams_enabled = this.information.meeting_teams_enabled;
    this.out_of_office_enabled = this.information.out_of_office_enabled;
    this.meeting_secured_send_ics = this.information.meeting_secured_send_ics;
    this.meeting_secured_send_btn_text =
      this.information.meeting_secured_send_btn_text;
    this.accounts_can_delegate = this.information.accounts_can_delegate;
    this.cost_center = this.information.cost_center;
    this.billing_receiver = this.information.billing_receiver;
    if(this.information.parent_uuid)
    {
      this.getParentObj(this.information.parent_uuid);
    }
  },
};
</script>
  <style></style>
  